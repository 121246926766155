import React from 'react';
import _ from 'lodash';

export default class Contact extends React.Component {
    render() {
        const redirectHandler = () =>{
            return <Redirect to='https://www.matchfixing.nl/bedankt/' />;
        }
        return (
            <section>
                <h2>{_.get(this.props, 'pageContext.site.siteMetadata.contact.title')}</h2>
                <form method="post" action="https://formspree.io/mail@floris.page" >
                    <div className="fields">
                        <input type="hidden" name="_next" value="https://www.matchfixing.nl/bedankt/"/>
                        <div className="field half">
                            <input type="text" name="name" id="name" placeholder="Naam" />
                        </div>
                        <div className="field half">
                            <input type="email" name="email" id="email" placeholder="Email" />
                        </div>
                        <div className="field">
                            <textarea name="message" id="message" placeholder="Bericht" />
                        </div>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Verzenden" className="primary" onClick={redirectHandler}/></li>
                    </ul>
                </form>
            </section>
        );
    }
}
